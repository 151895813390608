.Chatbox-container {
  position: relative;
  min-width: 420px;
  min-height: 480px;
  max-width: 1200px;
  height: 90vh;
  width: 60vw;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid;
  border-color: rgb(131 0 255);
}
.column-right {
  padding: 20px 20px 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  width: 100%;
}

.column-left {
  box-shadow: inset -2px 0 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(80% - 200px);
  padding: 20px;
  background-color: #e7f8ff;
  border-right: 2px solid;
  border-color: #e7f8ff;
}
