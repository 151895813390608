body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background-color: rgba(211, 211, 211, 0.728);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-container {
  position: static;
  height: 10%;
}
.right-header {
  padding: 15px 0px 0px 10px;
  font-size: 20px;
  font-weight: bolder;
  overflow: hidden;
  height: 10%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Set the width to 100% to span across the parent container */
  /* border-bottom: 1px solid black; Set the border properties as needed */
  background-color: #f9f7f7;
  border-bottom: 1px solid;
}

.results {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 8;
  padding: 10px;
  overflow-y: auto; /* Add scrollbar if content overflows */
  scroll-behavior: smooth;
  scroll-padding-bottom: 100px;
}
.msg-question {
  background-color: #007aff; /* Blue background color */
  color: #fff; /* White text color */
  padding: 10px 15px; /* Padding around the text */
  border-radius: 20px; /* Rounded corners */
  max-width: 80%; /* Maximum width for the message */
  align-self: flex-end; /* Align message to the right side */
  margin-bottom: 10px; /* Spacing between messages */
  /* Additional styles */
}

.msg-answer {
  background-color: #dadada;
  color: #424040;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 80%;
  align-self: flex-start;
  margin-bottom: 10px;
}

.left-header-container {
  display: flex;
  justify-content: space-between;
  padding: 14px 0px;
  align-items: center;
}
.left-header {
  padding: 14px 20px;
  font-size: 20px;
  font-weight: bolder;
  overflow: hidden;
}

.add-chat-btn {
  height: 10%;
  margin-top: 10%;
  display: flex;
  border-radius: 20px;
  background: blue;
  color: whtie;
}

.chat-room {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 10%;
  border: 1px solid green;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  justify-content: space-between;
}

.left-chat {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.chat-trash {
  background-color: transparent;
  border: none;
  margin-right: 15px;
  color: red;
  padding: 10px;
  cursor: pointer;
}
.chat-delete {
  background-color: transparent;
  border: none;
  margin-right: 5px;
  color: green;
  padding: 6px;
  cursor: pointer;
}
.chat-cancel {
  background-color: transparent;
  border: none;
  margin-right: 15px;
  color: red;
  padding: 6px;
  cursor: pointer;
}

.add-chat {
  cursor: pointer;
}
.add-chat :hover {
  background-color: rgb(90, 254, 53);
}

.left-column-footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 5px;
}
.settings {
  padding-left: 20px;
}

.settings-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  width: 50vw;
  height: 100%;
  background-color: #e7f8ff;
  z-index: 9999;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.submit-form {
  display: flex;
  flex-direction: column;
  /* Additional styles */
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  height: 2%;
}
.form-content {
  display: contents;
}

.submit-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.custom-select {
  border-radius: 10px;
  width: 30%;
  border: 1px solid rgb(154, 230, 255);
}
.text-box {
  height: 100%;
  width: 100%;
  border: 0px;
  resize: none;
}

.send-btn {
  background: transparent;
  border: none;
  height: 100%;
  width: 10%;
  cursor: pointer;
}

.send-btn:hover {
  background-color: #13e100;
}

textarea {
  border: none;
  outline: none;
}

.Chatbox-container {
  position: relative;
  min-width: 420px;
  min-height: 480px;
  max-width: 1200px;
  height: 90vh;
  width: 60vw;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid;
  border-color: rgb(131 0 255);
}
.column-right {
  padding: 20px 20px 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  width: 100%;
}

.column-left {
  box-shadow: inset -2px 0 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(80% - 200px);
  padding: 20px;
  background-color: #e7f8ff;
  border-right: 2px solid;
  border-color: #e7f8ff;
}

/* .container-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

