.submit-form {
  display: flex;
  flex-direction: column;
  /* Additional styles */
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  height: 2%;
}
.form-content {
  display: contents;
}

.submit-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.custom-select {
  border-radius: 10px;
  width: 30%;
  border: 1px solid rgb(154, 230, 255);
}
.text-box {
  height: 100%;
  width: 100%;
  border: 0px;
  resize: none;
}

.send-btn {
  background: transparent;
  border: none;
  height: 100%;
  width: 10%;
  cursor: pointer;
}

.send-btn:hover {
  background-color: #13e100;
}

textarea {
  border: none;
  outline: none;
}
