.left-header-container {
  display: flex;
  justify-content: space-between;
  padding: 14px 0px;
  align-items: center;
}
.left-header {
  padding: 14px 20px;
  font-size: 20px;
  font-weight: bolder;
  overflow: hidden;
}

.add-chat-btn {
  height: 10%;
  margin-top: 10%;
  display: flex;
  border-radius: 20px;
  background: blue;
  color: whtie;
}

.chat-room {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 10%;
  border: 1px solid green;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  justify-content: space-between;
}

.left-chat {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.chat-trash {
  background-color: transparent;
  border: none;
  margin-right: 15px;
  color: red;
  padding: 10px;
  cursor: pointer;
}
.chat-delete {
  background-color: transparent;
  border: none;
  margin-right: 5px;
  color: green;
  padding: 6px;
  cursor: pointer;
}
.chat-cancel {
  background-color: transparent;
  border: none;
  margin-right: 15px;
  color: red;
  padding: 6px;
  cursor: pointer;
}

.add-chat {
  cursor: pointer;
}
.add-chat :hover {
  background-color: rgb(90, 254, 53);
}

.left-column-footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 5px;
}
.settings {
  padding-left: 20px;
}

.settings-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
