.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  width: 50vw;
  height: 100%;
  background-color: #e7f8ff;
  z-index: 9999;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
