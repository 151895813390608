.header-container {
  position: static;
  height: 10%;
}
.right-header {
  padding: 15px 0px 0px 10px;
  font-size: 20px;
  font-weight: bolder;
  overflow: hidden;
  height: 10%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Set the width to 100% to span across the parent container */
  /* border-bottom: 1px solid black; Set the border properties as needed */
  background-color: #f9f7f7;
  border-bottom: 1px solid;
}

.results {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 8;
  padding: 10px;
  overflow-y: auto; /* Add scrollbar if content overflows */
  scroll-behavior: smooth;
  scroll-padding-bottom: 100px;
}
.msg-question {
  background-color: #007aff; /* Blue background color */
  color: #fff; /* White text color */
  padding: 10px 15px; /* Padding around the text */
  border-radius: 20px; /* Rounded corners */
  max-width: 80%; /* Maximum width for the message */
  align-self: flex-end; /* Align message to the right side */
  margin-bottom: 10px; /* Spacing between messages */
  /* Additional styles */
}

.msg-answer {
  background-color: #dadada;
  color: #424040;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 80%;
  align-self: flex-start;
  margin-bottom: 10px;
}
